import 'custom-event-polyfill';
import axios from 'axios';


export class EsAppHelper {
    public navigationWheel: NavigationWheel;
    constructor() {
        this.navigationWheel = new NavigationWheel();
    }
}

class NavigationWheel {
    private visible: boolean;
    constructor() {
        this.visible = true;
    }

    public isVisible() {
        return this.visible;
    }

    public show() {
        if (window) {
            const showNavWheelEvent = new CustomEvent('esapp.navigationwheel', {
                detail: {
                    visible: true,
                },
            });
            this.visible = true;
            document.dispatchEvent(showNavWheelEvent);
        }

    }
    public hide() {
        if (window) {
            const hideNavWheelEvent = new CustomEvent('esapp.navigationwheel', {
                detail: {
                    visible: false,
                },
            });
            this.visible = false;
            document.dispatchEvent(hideNavWheelEvent);
        }
    }

    public updateWheel(apiPrefix: string): void {
        const apiEndpoint = 'api/esapp/basket/itemcount';
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        axios.get(apiPrefix + apiEndpoint).then((response: any) => {
            if (!response || !response.data)
                return;

            const responseBasketCount = response.data.basketItemCount.toString();

            sessionStorage.setItem('esAppBasketCount', responseBasketCount);

            document.dispatchEvent(new CustomEvent('basketChanged'));
            document.dispatchEvent(new CustomEvent('esApp.basketChanged'));
        });
    }
}
